<template>
  <v-row no-gutters class="fill-height">
    <v-navigation-drawer color="grey lighten-5" permanent expand-on-hover>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>settings</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-h6">Settings</v-list-item-title>
            <v-list-item-subtitle v-if="$route.name" class="text-capitalize">
              {{ $route.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <template v-for="item in items">
          <v-list-item
            v-if="item.access"
            :key="item.title"
            :to="item.to"
            color="primary darken-1"
            dense
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.subtitle">{{ item.subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-col>
      <v-container fluid>
        <v-row>
          <!-- Breadcrumbs -->
          <v-col cols="12" v-if="breadcrumbs">
            <v-breadcrumbs :items="breadcrumbs" />
            <v-divider />
          </v-col>

          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'pibot-settings',
  computed: {
    items () {
      return [
        { title: 'Profile', icon: 'account_box', to: '/settings/profile', access: this.$grantAccess(this.$me, 'readOwn', 'profile') },
        // { title: 'Users', icon: 'supervised_user_circle' },
        { title: 'Admin', icon: 'supervisor_account', to: '/settings/users', access: this.$grantAccess(this.$me, 'readAny', 'profile') },
        { title: 'Super Admin', icon: 'important_devices', to: '/settings/super', access: this.$me.role === 'superadmin' }
      ]
    },
    breadcrumbs () {
      return this.$breadcrumbs.map(b => ({
        text: b.meta.breadcrumb,
        to: b.path,
        exact: true,
        disabled: this.$route.name === b.name
      }))
    }
  }
}
</script>
